
const HomeVideoList = [

  { filename: 'KoccaFW13_Edit04_1080p422H&G', id: '1' },
  { filename: 'DuttiMadeToOrder2015_E1Color_422', id: '2' },
  { filename: 'Eral_FW15_E4_Nologo_28s_1920x810_422', id: '3' },
  { filename: '0737_Textura_SS16_Film_E4_S4_CScopeHYG_422', id: '4' },
  { filename: '0735_Hawkers_SS16_E5_C2_L2_422', id: '5' },
  { filename: '0736_MassimoDutti_Equestrian_SS16_E2_S2_1920x810_1_422', id: '6' },
  { filename: '0749_Dutti_Hamptons_W16_E4_1920x1080_C1_422', id: '7' },
  { filename: '0765_Network_E2A_C1_60s_1', id: '8' },
  { filename: '0766_ECI_EnfasisBlack18_E3_S7_NoLogos', id: '9' },
  { filename: '0768_NTW_FW19_E3B_30s_S2_C1_HD_NoLogo_Prores422', id: '10' },
  { filename: '0748_Fabletics_Fall16_November_Horizons2_E4_C1_EDL_1920x810_Crop_444', id: '13' },
  { filename: '0748_Fabletics_Fall16_Celebrations_E2_C1_1920x810_444', id: '14' },
  { filename: 'SUMMERTIME BANO30v2C1', id: '16' },
  { filename: 'SUMMERTIMEMODA30v2C1', id: '15' },
  { filename: '0762_ERAL_SS17_E4_30s_C1_OK_422', id: '17' },
  { filename: '0752_DuttiPressSS17_E2_C1_1920x1080_422', id: '18' },
  { filename: '41_Kocca_SS16_E1_C2_1920x810_HGDir_White', id: '19' },
  { filename: '0745_MNG_Journeys_CHI_Camille_E4_C3_NoSubs_422', id: '20' },
  { filename: '0745_MNG_Journeys_ChII_Julia_Teaser_E1_C1_20s_NoSubs_422', id: '21' },
  { filename: '0745_MNG_Journeys_ChIII_Alma_Main_E3_C1_1920x810_NoSubs_422', id: '22' },
  { filename: '0756_JourneysMango_II_Teaser_20s_E2_C1_422', id: '23' },
  { filename: '0760_Journeys_Thailand_SS17_E9_C1_A1_NoSubs_422', id: '24' },
  { filename: '0769NorthSails_summer 18_E1B_C1_422', id: '26' },
  { filename: '0779_BolonHaileyBaldwin_30s_C1_A1', id: '35' },
  { filename: '0776_Bolon_AnneHathaway_30s_C1_A1', id: '36' }

]
export default HomeVideoList

import React, { useRef, useState, useEffect } from 'react'
import { MotionMaskHome } from '../MotionMaskHome'

import './style.scss'

import VideoList from '../Data/VideoList'
import HomeVideoList from '../Data/HomeVideoList'
import { Link } from 'react-router-dom'

const title = 'Bapost - Remote Postproduction'
const url = 'https://bapost.online/static/media/videos/Home_reel/'

export default function Home () {
  // title change
  useEffect(() => {
    document.title = title
  }, [title])

  const videoElement = useRef(null)
  const videoElement2 = useRef(null)
  const [isActive, setActive] = useState('false')

  const togglePlay = (n) => {
    if (n === 0) {
      setActive(true)
      videoElement.current.play()
      setFrom(isNext)
      setCurrentId(isNextId)
    }
    if (n === 1) {
      videoElement2.current.play()
      setFrom(isNext)
      setCurrentId(isNextId)
      setActive(false)
    }
  }

  const handleOnTimeUpdate = (e) => {
    const progress = (videoElement.current.currentTime / videoElement.current.duration) * 100
    if (progress >= 85) {
      togglePlay(1)
    }
  }
  const handleOnTimeUpdate2 = (e) => {
    const progress = (videoElement2.current.currentTime / videoElement2.current.duration) * 100
    if (progress > 85) {
      togglePlay(0)
    }
  }
  function getRandomInt (max) {
    return Math.floor(Math.random() * max)
  }
  const random = getRandomInt(HomeVideoList.length)
  const random2 = getRandomInt(HomeVideoList.length)

  const post = VideoList[VideoList.length - HomeVideoList[random].id]
  const Director = post.director.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
  const Name = post.name.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
  const Client = post.client.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
  const Index = post.id.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
  const FinalText = Name + '-by-' + Director + '-for-' + Client + '-$' + Index
  const [isCurrentId, setCurrentId] = useState(FinalText)

  const post2 = VideoList[VideoList.length - HomeVideoList[random2].id]
  const Director2 = post2.director.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
  const Name2 = post2.name.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
  const Client2 = post2.client.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
  const Index2 = post2.id.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
  const FinalText2 = Name2 + '-by-' + Director2 + '-for-' + Client2 + '-$' + Index2

  const [isNextId, setNextId] = useState(FinalText2)
  const [isNext, setNext] = useState(post2.name + ' / ' + post2.client)
  // const [isRandom, setRandom] = useState(random)
  const [isVideo, setVideo] = useState(url + HomeVideoList[random].filename + '.mp4')
  const [isVideo2, setVideo2] = useState(url + HomeVideoList[random2].filename + '.mp4')
  const [isKey, setKey] = useState(HomeVideoList[random].filename)
  const [isKey2, setKey2] = useState(HomeVideoList[random2].filename)
  const [isFrom, setFrom] = useState(VideoList[VideoList.length - HomeVideoList[random].id].name + ' / ' + VideoList[VideoList.length - HomeVideoList[random].id].client)
  const [isEnded, setEnded] = useState(false)

  const handleVideoAutoEnded = () => {
    const random = getRandomInt(HomeVideoList.length)
    const post = VideoList[VideoList.length - HomeVideoList[random].id]
    const Director = post.director.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
    const Name = post.name.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
    const Client = post.client.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
    const Index = post.id.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
    const FinalText = Name + '-by-' + Director + '-for-' + Client + '-$' + Index
    setCurrentId(FinalText)
    setVideo(url + HomeVideoList[random].filename + '.mp4')
    // setCurrentId(HomeVideoList[random].id)
    setKey(HomeVideoList[random].filename)
    setNext(post.name + ' / ' + post.client)
    setNextId(FinalText)
    setEnded(true)
    setTimeout(() => {
      console.log('timeout 1') // count is 0 here
    }, 3000)
  }

  const handleVideoEnded2 = () => {
    const random2 = getRandomInt(HomeVideoList.length)
    const post = VideoList[VideoList.length - HomeVideoList[random2].id]
    const Director = post.director.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
    const Name = post.name.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
    const Client = post.client.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
    const Index = post.id.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
    const FinalText = Name + '-by-' + Director + '-for-' + Client + '-$' + Index
    setCurrentId(FinalText)
    setVideo2(url + HomeVideoList[random2].filename + '.mp4')
    // setCurrentId(HomeVideoList[random2].id)
    setKey2(HomeVideoList[random2].filename)
    setNext(post.name + ' / ' + post.client)
    setNextId(FinalText)
    setTimeout(() => {
      console.log('timeout 2')
    }, 3000)
  }

  // const defaultTitle = useRef(document.title)

  return (
    <>

      <MotionMaskHome />
      <div className='home-container'>
        <div className='video-container-wrapper-1'>
          <div className='video-container'>
            {isEnded
              ? <video muted playsInline preload='auto' ref={videoElement} className='home-video-player' onEnded={handleVideoAutoEnded} key={isKey} onTimeUpdate={handleOnTimeUpdate} id='home-video-1'><source className='video-bg' src={isVideo} type='video/mp4' />Your browser does not support HTML5 video.</video>
              : <video autoPlay muted playsInline preload='auto' ref={videoElement} className='home-video-player-a' onEnded={handleVideoAutoEnded} key={isKey} onTimeUpdate={handleOnTimeUpdate} id='home-video-1'><source className='video-bg' src={isVideo} type='video/mp4' />Your browser does not support HTML5 video.</video>}
          </div>
        </div>
        <div className='video-container-wrapper-2'>
          <div className='video-container'>
            <video muted preload='auto' playsInline ref={videoElement2} className={isActive ? 'video-invisible' : 'home-video-player'} onEnded={handleVideoEnded2} key={isKey2} onTimeUpdate={handleOnTimeUpdate2} id='home-video-2'><source className='video-bg' src={isVideo2} type='video/mp4' />Your browser does not support HTML5 video.</video>
          </div>
        </div>
      </div>
      <div className='section-container'>
        <div className='section-content-container'>
          <div className='top-spacer'>&nbsp;</div>
          <div className='section-title-container'>
            <div className='section-title-text-container'>
              <div className='section-title-text'>Selected Shots</div>
            </div>
            <div className='section-subtitle-container'><Link to={`/Works/${isCurrentId}`}><div className='home-video-link'>{isFrom}</div></Link></div>
          </div>
          <div className='section-title-liner'><div className='section-title-liner-wrap' /></div>
        </div>

      </div>
    </>

  )
}

import { useState, useRef, useEffect } from 'react'

import './style.scss'
import VideoList from '../Data/VideoList'
import VideoPlayer from '../VideoPlayer'

import gsap from 'gsap'

import {
  FaMobileAlt,
  FaDesktop,
  FaInstagramSquare,
  FaSearch
} from 'react-icons/fa'
import { RiScissorsCutFill } from 'react-icons/ri'
import {
  MdHd,
  MdColorLens,
  MdClosedCaption,
  MdAnimation,
  MdClose,
  MdAutoFixHigh,
  MdHeadphones,
  MdFormatClear,
  MdDevices,
  Md4K
} from 'react-icons/md'
import { BsSoundwave } from 'react-icons/bs'

import { Link } from 'react-router-dom'

const title = 'Bapost - Works - Videotheque'
const url = 'https://bapost.online/static/media/videos/'

function IcoList ({ number }) {
  const n = VideoList[number].work.length
  // const cont = number
  // const Icon = icons[number];
  // Slice function call

  const items = VideoList[number].work.slice(0, n).map((i) => {
    // console.log(i)
    if (i === 'Directing') {
      return (
        <div className='work-list-ico' key={`icosss-${i}`} title='Edition'>
          <RiScissorsCutFill title='Directing' />
        </div>
      )
    }
    if (i === 'Edition') {
      return (
        <div className='work-list-ico' key={`icosss-${i}`} title='Edition'>
          <RiScissorsCutFill title='Edition' />
        </div>
      )
    }
    if (i === 'Color Grading') {
      return (
        <div className='work-list-ico' key={`icosss-${i}`} title='Color Grading'>
          <MdColorLens title='Color Grading' />
        </div>
      )
    }
    if (i === 'Sound Mixing') {
      return (
        <div className='work-list-ico' key={`icosss-${i}`}>
          <MdHeadphones title='Sound Mixing' />
        </div>
      )
    }
    if (i === 'Music Composition') {
      return (
        <div className='work-list-ico' key={`icosss-${i}`} title='Sound Fx'>
          <BsSoundwave title='Music Composition' />
        </div>
      )
    }
    if (i === 'Vfx') {
      return (
        <div className='work-list-ico' key={`icosss-${i}`} title='Vfx'>
          <MdAutoFixHigh title='Vfx' />
        </div>
      )
    }
    if (i === 'Motion Graphics') {
      return (
        <div className='work-list-ico' key={`icosss-${i}`} title='Motion Graphics'>
          <MdFormatClear title='Motion Graphics' />
        </div>
      )
    }
    if (i === 'Animation') {
      return (
        <div className='work-list-ico' key={`icosss-${i}`} title='Animation'>
          <MdAnimation title='Animation' />
        </div>
      )
    }
    if (i === 'Hd') {
      return (
        <div className='work-list-ico' key={`icosss-${i}`} title='Hd'>
          <MdHd title='Hd' />
        </div>
      )
    }
    if (i === '4k') {
      return (
        <div className='work-list-ico' key={`icosss-${i}`} title='4K'>
          <Md4K title='4K' />
        </div>
      )
    }
    if (i === 'CC') {
      return (
        <div className='work-list-ico' key={`icosss-${i}`} title='CC'>
          <MdClosedCaption title='CC' />
        </div>
      )
    }
    if (i === 'Formats') {
      return (
        <div className='work-list-ico' key={`icosss-${i}`} title='Various Devices'>
          <MdDevices title='Various Devices' />
        </div>
      )
    } else {
      return <div className='work-list-ico' key={`icosss-${i}`} />
    }
  })

  return (
    <div className='work-iconos-container' key={`icono-${number}`}>
      {items}
    </div>
  )
}
function IcoList2 ({ number }) {
  const n = VideoList[number].work.length
  // const cont = number
  // const Icon = icons[number];
  // Slice function call

  const items = VideoList[number].work.slice(0, n).map((i) => {
    // console.log(i)
    if (i === 'Directing') {
      return (
        <div className='work-list-ico' key={`icons-${i}`} title='Edition'>
          <RiScissorsCutFill title='Directing' />
        </div>
      )
    }
    if (i === 'Edition') {
      return (
        <div className='work-list-ico' key={`icons-${i}`} title='Edition'>
          <RiScissorsCutFill title='Edition' />
        </div>
      )
    }
    if (i === 'Color Grading') {
      return (
        <div className='work-list-ico' key={`icons-${i}`} title='Color Grading'>
          <MdColorLens title='Color Grading' />
        </div>
      )
    }
    if (i === 'Sound Mixing') {
      return (
        <div className='work-list-ico' key={`icons-${i}`}>
          <MdHeadphones title='Sound Mixing' />
        </div>
      )
    }
    if (i === 'Music Composition') {
      return (
        <div className='work-list-ico' key={`icons-${i}`} title='Sound Fx'>
          <BsSoundwave title='Music Composition' />
        </div>
      )
    }
    if (i === 'Vfx') {
      return (
        <div className='work-list-ico' key={`icons-${i}`} title='Vfx'>
          <MdAutoFixHigh title='Vfx' />
        </div>
      )
    }
    if (i === 'Motion Graphics') {
      return (
        <div className='work-list-ico' key={`icons-${i}`} title='Motion Graphics'>
          <MdFormatClear title='Motion Graphics' />
        </div>
      )
    }
    if (i === 'Animation') {
      return (
        <div className='work-list-ico' key={`icons-${i}`} title='Animation'>
          <MdAnimation title='Animation' />
        </div>
      )
    }
    if (i === 'Hd') {
      return (
        <div className='work-list-ico' key={`icons-${i}`} title='Hd'>
          <MdHd title='Hd' />
        </div>
      )
    }
    if (i === '4k') {
      return (
        <div className='work-list-ico' key={`icons-${i}`} title='4K'>
          <Md4K title='4K' />
        </div>
      )
    }
    if (i === 'CC') {
      return (
        <div className='work-list-ico' key={`icons-${i}`} title='CC'>
          <MdClosedCaption title='CC' />
        </div>
      )
    }
    if (i === 'Formats') {
      return (
        <div className='work-list-ico' key={`icons-${i}`} title='Various Devices'>
          <MdDevices title='Various Devices' />
        </div>
      )
    } else {
      return <div className='work-list-ico' key={`icons-${i}`} />
    }
  })

  return (
    <div className='work-iconos-container' key={`icono-${number}`}>
      {items}
    </div>
  )
}

// default function
export default function Work () {
  useEffect(() => {
    document.title = title
  }, [title])

  const [modalVideo, setModalVideo] = useState(0)
  const [isModalVis, setModalVis] = useState(false)
  const [isVideo, setVideo] = useState(0)
  function PreviewList ({ number }) {
    const items = VideoList[number].version.slice(0, VideoList[number].version.length).map((i) => {
      return (
        <div key={i + 'prevlist' + number}>
          <span
            className='work-preview-text'
            onClick={() =>
              handleClick(number, VideoList[number].version.indexOf(i), 0)}
          >
            {i}
          </span>
          <span className='work-preview-bar'>&nbsp;/&nbsp;</span>

        </div>
      )
    })
    return (
      <>
        <FaDesktop className='preview-ico' />
        {items}
      </>
    )
  }
  function PreviewList2 ({ number }) {
    const items = VideoList[number].version.slice(0, VideoList[number].version.length).map((i) => {
      return (
        <div key={i + i + 'prevlist' + number}>
          <span
            className='work-preview-text'
            onClick={() =>
              handleClick(number, VideoList[number].version.indexOf(i), 0)}
          >
            {i}
          </span>
          <span className='work-preview-bar'>&nbsp;/&nbsp;</span>

        </div>
      )
    })
    return (
      <>
        <FaDesktop className='preview-ico' />
        {items}
      </>
    )
  }

  function PreviewListVert ({ number }) {
    if (VideoList[number].vertical.length > 0) {
      const items = VideoList[number].vertical.slice(0, VideoList[number].vertical.length).map((i) => {
        return (
          <div key={i + 'vertlist' + number + i}>
            <span
              className='work-preview-text'
              onClick={() =>
                handleClick(number, VideoList[number].vertical.indexOf(i), 1)}
            />
            <span className='work-preview-text'>
              {i}
            </span>
            <span className='work-preview-bar'>&nbsp;/&nbsp;</span>
          </div>
        )
      })
      return (
        <>
          <FaMobileAlt className='preview-ico' />
          {items}
        </>
      )
    } else {
      return <></>
    }
  }
  function PreviewListVert2 ({ number }) {
    if (VideoList[number].vertical.length > 0) {
      const items = VideoList[number].vertical.slice(0, VideoList[number].vertical.length).map((i) => {
        return (
          <div key={i + i + 'vertlist' + number + i}>
            <span
              className='work-preview-text'
              onClick={() =>
                handleClick(number, VideoList[number].vertical.indexOf(i), 1)}
            />
            <span className='work-preview-text'>
              {i}
            </span>
            <span className='work-preview-bar'>&nbsp;/&nbsp;</span>

          </div>
        )
      })
      return (
        <>
          <FaMobileAlt className='preview-ico' />
          {items}
        </>
      )
    } else {
      return <></>
    }
  }

  function PreviewListSquare ({ number }) {
    const n = VideoList[number].square.length

    if (VideoList[number].square.length > 0) {
      const items = VideoList[number].square.slice(0, n).map((i) => {
        return (
          <div key={i + 'squarelist' + number + i}>
            <span
              className='work-preview-text'
              onClick={() =>
                handleClick(number, VideoList[number].square.indexOf(i), 2)}
            />
            <span className='work-preview-text'>
              {i}
            </span>
            <span className='work-preview-bar'>&nbsp;/&nbsp;</span>

          </div>
        )
      })
      return (
        <>
          <FaInstagramSquare className='preview-ico' />
          {items}
        </>
      )
    } else {
      return <></>
    }
  }
  function PreviewListSquare2 ({ number }) {
    const n = VideoList[number].square.length

    if (VideoList[number].square.length > 0) {
      const items = VideoList[number].square.slice(0, n).map((i) => {
        return (
          <div key={i + i + 'squarelist' + number + i}>
            <span
              className='work-preview-text'
              onClick={() =>
                handleClick(number, VideoList[number].square.indexOf(i), 2)}
            >
              {i} /
            </span>
          </div>
        )
      })
      return (
        <>
          <FaInstagramSquare className='preview-ico' />
          {items}
        </>
      )
    } else {
      return <></>
    }
  }

  const url0 =
    url +
    VideoList[isVideo].folder +
    '/' +
    VideoList[isVideo].filename +
    '.mp4'

  const [isUrl, setUrl] = useState(url0)

  const handleClick = (video, version, format) => {
    if (format === -1) {
      setModalVis(!isModalVis)
    }
    if (format === 0) {
      if (version === 0) {
        // // setVersion(0)
        setModalVideo(video)
        setVideo(video)
        setUrl(
          url +
            VideoList[video].folder +
            '/' +
            VideoList[video].filename +
            '.mp4'
        )
        setModalVis(!isModalVis)
      } else {
        // // setVersion(version)
        setModalVideo(video)
        setVideo(video)
        setUrl(
          url +
            VideoList[video].folder +
            '/Ver/' +
            VideoList[video].filename +
            '_' +
            VideoList[video].version[version] +
            '.mp4'
        )
        setModalVis(!isModalVis)
      }
    }
    if (format === 1) {
      if (version === 0) {
        // setVersion(0)
        setModalVideo(video)
        setVideo(video)
        setUrl(
          url +
            VideoList[video].folder +
            '/Ver/' +
            VideoList[video].filename +
            '_vert.mp4'
        )
        setModalVis(!isModalVis)
      } else {
        // setVersion(version)
        setModalVideo(video)
        setVideo(video)
        setUrl(
          url +
            VideoList[video].folder +
            '/Ver/' +
            VideoList[video].filename +
            '_vert' +
            VideoList[video].vertical[version] +
            '.mp4'
        )
        setModalVis(!isModalVis)
      }
    }
    if (format === 2) {
      if (version === 0) {
        // setVersion(0)
        setModalVideo(video)
        setVideo(video)
        setUrl(
          url +
            VideoList[video].folder +
            '/Ver/' +
            VideoList[video].filename +
            '_square.mp4'
        )
        setModalVis(!isModalVis)
      } else {
        // setVersion(version)
        setModalVideo(video)
        setVideo(video)
        setUrl(
          url +
            VideoList[video].folder +
            '/Ver/' +
            VideoList[video].filename +
            '_vert' +
            VideoList[video].square[version] +
            '.mp4'
        )
        setModalVis(!isModalVis)
      }
    }
  }
  function ModalVideo () {
    const pel = useRef()
    const ModalTl = useRef()
    // const sel = gsap.utils.selector(pel);

    useEffect(() => {
      ModalTl.current && ModalTl.current.progress(0).kill()
      ModalTl.current = gsap
        .timeline({ defaults: { ease: 'expo' } })
        // Inicio mover caja y bajar texto
        .fromTo(pel.current, { y: '-100%' }, { y: 0, duration: 0.5 })
    }, [])
    //  src={url+VideoList[isVideo].folder+"/"+VideoList[isVideo].filename+".mp4"}
    return (
      <>
        <div className='modal-video-container' ref={pel} id='modal-video'>
          <div className='modal-video-wrapper'>
            <VideoPlayer src={isUrl} />
          </div>
        </div>
      </>
    )
  }

  const [state, setstate] = useState({
    query: '',
    list: []
  })
  const handleChange = (e) => {
    const results = VideoList.filter((post, index) => {
      return post.name
        .concat(post.client)
        .toLowerCase()
        .includes(e.target.value.toLowerCase())
    })
    setstate({
      query: e.target.value,
      list: results
    })
    // searchUpdate()
  }
  const [loaded, setLoaded] = useState(false)
  return (
    <>
      {isModalVis && (
        <>
          <div className='x-close' onClick={() => handleClick(0, 0, -1)}>
            <MdClose />
          </div>
          <ModalVideo video={modalVideo} />
        </>
      )}
      <div className='section-container'>
        <div className='section-content-with-scroll-container'>
          <div className='top-spacer'>&nbsp;</div>
          <div className='section-title-container-scroll'>
            <div className='section-title-text-container'>
              <div className='section-title-text'>Videotheque</div>
            </div>
            <div className='work-search-text-container'>
              <div className='search-box-wrapper'>
                <FaSearch className='ico-search' />
                <input
                  className='work-search'
                  onChange={handleChange}
                  value={state.query}
                  type='search'
                />
              </div>
            </div>
          </div>
          <div className='section-title-liner'><div className='section-title-liner-wrap-scroll' /></div>
          <div className='works-list-container'>
            {state.query === ''
              ? VideoList.map((post, index) => {
                const Name = post.name
                const NameReplace = Name.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
                const Client = post.client
                const ClientReplace = Client.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
                const Director = post.director
                const DirectorReplace = Director.replace(/[-\s]+/g, '-').replace(/^-/, '').replace(/[^a-zA-Z0-9àç_èéù-]+/g, '').toLowerCase()
                const Index = post.id
                const FinalName = NameReplace + '-by-' + DirectorReplace + '-for-' + ClientReplace + '-$' + Index
                return (
                  <div className='work-list-item' key={post.filename}>
                    <div className='work-content-container'>
                      <div className='work-line-container'>
                        <div className='work-title-text-container'>
                          <span className='client-text'>
                            {post.id}&nbsp;
                          </span>
                          <Link
                            to={`/Works/${FinalName}`}
                            className='work-title-link'
                          >
                            {post.name}
                          </Link>
                        </div>
                      </div>
                      <div className='work-line-container'>
                        <div className='work-subtitle-text-container'>
                          <span className='client-text'>
                            client&nbsp;/&nbsp;
                          </span>
                          <span className='work-client-text'>
                            {post.client}
                          </span>
                        </div>
                      </div>
                      <div className='work-line-container'>
                        <div className='work-subtitle-text-container'>
                          <IcoList number={VideoList.length - Index} />
                        </div>
                      </div>
                      <div className='work-line-container'>
                        <div className='work-subtitle-text-container'>
                          <div className='work-subtitle-preview-container'>
                            <div className='work-list-preview-ico'>
                              <div className='work-list-line-preview-ico'><PreviewList number={VideoList.length - Index} /></div>
                              <div className='work-list-line-preview-ico'><PreviewListVert number={index} /></div>
                              <div className='work-list-line-preview-ico'><PreviewListSquare number={index} /></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='work-list-image-container'>
                      <div className='work-list-image-wrapper' onClick={() => handleClick(index, 0, 0)}>
                        <img onLoad={() => setLoaded(true)} className={loaded ? 'loaded' : ''} src={url + VideoList[index].folder + '/' + VideoList[index].filename + '.jpg'} />
                      </div>
                    </div>
                    <div className='work-spacer-scroll-line'><div className='center-line' /></div>
                  </div>
                )
              })
              : !state.list.length
                  ? 'Your query did not return any results'
                  : state.list.map((post, index) => {
                    return (
                      <div className='work-list-item2' key={post.filename + index}>
                        <div className='work-content-container'>
                          <div className='work-line-container'>
                            <div className='work-subtitle-text-container'>
                              <span className='client-text'>
                                {post.id}&nbsp;
                              </span>
                              <Link
                                to={`/Works/${post.id}`}
                                className='work-title-link'
                              >
                                {post.name}
                              </Link>
                            </div>
                          </div>
                          <div className='work-line-container'>
                            <div className='work-subtitle-text-container'>
                              <span className='client-text'>
                                client&nbsp;/&nbsp;{VideoList.length - post.id}
                              </span>
                              <span className='work-subtitle-text'>
                                {post.client}
                              </span>
                            </div>
                          </div>
                          <div className='work-line-container'>
                            <div className='work-subtitle-text-container'>
                              <IcoList2 number={VideoList.length - post.id} />
                            </div>
                          </div>
                          <div className='work-line-container'>
                            <div className='work-subtitle-preview-container'>
                              <div className='work-list-preview-ico'>
                                <PreviewList2 number={VideoList.length - post.id} />
                                <PreviewListVert2 number={VideoList.length - post.id} />
                                <PreviewListSquare2 number={VideoList.length - post.id} />

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='work-list-image-container' onClick={() => handleClick(index, 0, 0)}>
                          <img onLoad={() => setLoaded(true)} className={loaded ? 'loaded' : ''} src={url + VideoList[VideoList.length - post.id].folder + '/' + VideoList[VideoList.length - post.id].filename + '.jpg'} />
                        </div>
                        <div className='work-spacer-scroll-line'><div className='center-line' /></div>
                      </div>
                    )
                  })}
          </div>
        </div>
        <div className='scroll-mask' />
      </div>
    </>
  )
}

const VideoList = [
  {
    id: '49',
    folder: '02_Prana_Campaign_SS21',
    name: 'Ice Artists',
    filename: 'IceArtists_Prana_E1',
    client: 'Prana',
    director: 'hunter and gatti',
    version: ['0:49'],
    work: ['Edition', 'Color Grading', 'Sound Mixing', 'Hd'],
    vertical: [],
    square: []
  },
  {
    id: '48',
    folder: '02_Prana_Campaign_SS21',
    name: 'SS Campaign',
    filename: 'Prana_Global_2021_E2_C2',
    client: 'Prana',
    director: 'hunter and gatti',
    version: ['1:03', '30', '15', '6'],
    work: ['Edition', 'Color Grading', 'Sound Mixing', 'Hd', 'Formats'],
    vertical: ['0:15'],
    square: ['0:15']
  },
  {
    id: '47',
    folder: '02_Prana_Campaign_SS21',
    name: 'City Bikers',
    filename: 'Prana_BikerS_E2_Main',
    client: 'Prana',
    director: 'hunter and gatti',
    version: ['0:57', '30', '15', '6'],
    work: ['Edition', 'Color Grading', 'Sound Mixing', 'Hd', 'Formats'],
    vertical: ['0:15'],
    square: ['0:15']
  },
  {
    id: '46',
    folder: '02_Prana_Campaign_SS21',
    name: 'ReZion Collection',
    filename: 'Prana_ReZion_2021_FW_E3_C2',
    client: 'Prana',
    director: 'hunter and gatti',
    version: ['0:46', '30', '15', '6'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animnation',
      'Sound Mixing',
      'Hd',
      'Formats'
    ],
    vertical: ['0:15'],
    square: ['0:15']
  },
  {
    id: '45',
    folder: '02_Prana_Campaign_SS21',
    name: 'Openhill summit',
    filename: 'Prana_Openhill_E1D_Main',
    client: 'Prana',
    director: 'hunter and gatti',
    version: ['0:41', '30', '6'],
    work: ['Edition', 'Color Grading', 'Sound Mixing', 'Hd', 'Formats'],
    vertical: ['0:15'],
    square: ['0:15']
  },

  {
    id: '44',
    folder: '01_MNG_MediterraneanSeas',
    name: 'Med Seeker Ch III Julia',
    filename: 'MANGO_MED_CH3_JULIA_Master_V5_E2_C1_A4',
    client: 'Mango',
    director: 'hunter and gatti',
    version: ['5:41'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },
  {
    id: '43',
    folder: '01_MNG_MediterraneanSeas',
    name: 'Med Seeker Ch II Giacomo',
    filename: 'MNG_MED_CHP2_GIACOMO_E3_A3_C1_NoGraph',
    client: 'Mango',
    director: 'hunter and gatti',
    version: ['5:00'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },
  {
    id: '42',
    folder: '01_MNG_MediterraneanSeas',
    name: 'Med Seeker Ch I Desiree',
    filename: 'MNG_MED_CHP1_DESIRE_V3_MAIN_A2_L1_C1B_YT',
    client: 'Mango',
    director: 'hunter and gatti',
    version: ['5:58'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Sound Mixing',
      'Music Composition',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },
  {
    id: '41',
    folder: 'Armani_encounters',
    name: 'Encounters Tokyo',
    filename: 'AR_Encounters_JapanTokyo_1920x800_422',
    client: 'Armani',
    director: 'hunter and gatti',
    version: ['1:52', 'FR', 'EN'],
    work: [
      'Edition',
      'Color Grading',
      'Sound Mixing',
      'Music Composition',
      'Hd',
      'CC',
      'Formats'
    ],
    vertical: ['0:16', 'EN', 'FR'],
    square: []
  },
  {
    id: '40',
    folder: 'Armani_encounters',
    name: 'Encounters Krathong',
    filename: 'AR_Encounters_ThailandKrathong_1920x800_422',
    client: 'Armani',
    director: 'hunter and gatti',
    version: ['1:20', 'FR', 'EN'],
    work: [
      'Edition',
      'Color Grading',
      'Sound Mixing',
      'Music Composition',
      'Hd',
      'CC',
      'Formats'
    ],
    vertical: ['0:13', 'EN', 'FR'],
    square: []
  },
  {
    id: '39',
    folder: 'Armani_encounters',
    name: 'Encounters Flamenco',
    filename: 'AR_Encounters_SpainFlamenco_1920x800_422',
    client: 'Armani',
    director: 'hunter and gatti',
    version: ['1:31', 'EN', 'FR'],
    work: [
      'Edition',
      'Color Grading',
      'Sound Mixing',
      'Music Composition',
      'Hd',
      'CC',
      'Formats'
    ],
    vertical: ['0:13', 'EN'],
    square: []
  },
  {
    id: '38',
    folder: 'Armani_encounters',
    name: 'Encounters Japanesse Tea',
    filename: 'AR_Encounters_JapanTea_1920x800_422',
    client: 'Armani',
    director: 'hunter and gatti',
    version: ['1:42', 'EN', 'FR'],
    work: [
      'Edition',
      'Color Grading',
      'Sound Mixing',
      'Music Composition',
      'Hd',
      'CC',
      'Formats'
    ],
    vertical: ['0:13', 'EN', 'FR'],
    square: []
  },
  {
    id: '37',
    folder: 'Armani_encounters',
    name: 'Encounters India',
    filename: 'AR_Encounters_India_1920x800_422',
    client: 'Armani',
    director: 'hunter and gatti',
    version: ['1:25', 'EN', 'FR'],
    work: [
      'Edition',
      'Color Grading',
      'Sound Mixing',
      'Music Composition',
      'Hd'
    ],
    vertical: [],
    square: []
  },

  {
    id: '36',
    folder: 'Bolon',
    name: 'I See Me by Anne Hathaway',
    filename: '0776_Bolon_AnneHathaway_30s_C1_A1',
    client: 'Bolon Eyewear',
    director: 'hunter and gatti',
    version: ['0:30', '15', '5'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Sound Mixing',
      'Music Composition',
      'Hd'
    ],
    vertical: [],
    square: []
  },
  {
    id: '35',
    folder: 'Bolon',
    name: 'I See Me by Hailey Baldwin',
    filename: '0779_BolonHaileyBaldwin_30s_C1_A1',
    client: 'Bolon Eyewear',
    director: 'hunter and gatti',
    version: ['0:30', '15', '5'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Sound Mixing',
      'Music Composition',
      'Hd'
    ],
    vertical: ['0:30'],
    square: []
  },
  {
    id: '34',
    folder: 'NS_india',
    name: 'NS India Teaser Trailer',
    filename: '0775_NS_India_TeaserTrailer_4K_E2_C1_HD',
    client: 'North Sails',
    director: 'hunter and gatti',
    version: ['0:57', 'UHD'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Sound Mixing',
      'Music Composition',
      'Hd',
      '4k'
    ],
    vertical: [],
    square: []
  },
  {
    id: '33',
    folder: 'NS_india',
    name: 'NS India CH IV Gotzon',
    filename: '0781_NSIndia_CH3_Gotzon_E3_C1_HD',
    client: 'North Sails',
    director: 'hunter and gatti',
    version: ['1:24', 'EN'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Sound Mixing',
      'Music Composition',
      'Hd'
    ],
    vertical: [],
    square: []
  },

  {
    id: '32',
    folder: 'NS_india',
    name: 'NS India CH III Alana',
    filename: '0781_NSIndia_CH3_Alana_V3_C1_HD',
    client: 'North Sails',
    director: 'hunter and gatti',
    version: ['1:40', 'EN'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Sound Mixing',
      'Music Composition',
      'Hd'
    ],
    vertical: [],
    square: []
  },

  {
    id: '31',
    folder: 'NS_india',
    name: 'NS India CH II Michel',
    filename: '0775_NS_India_CH2_Michel_E1D_A1_C1',
    client: 'North Sails',
    director: 'hunter and gatti',
    version: ['1:33', 'EN'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Sound Mixing',
      'Music Composition',
      'Hd'
    ],
    vertical: [],
    square: []
  },
  {
    id: '30',
    folder: 'NS_india',
    name: 'NS India CH I Simon',
    filename: '0775_NS_India_Simon_4K_E2_A3B_C1',
    client: 'North Sails',
    director: 'hunter and gatti',
    version: ['1:57', 'EN', '6'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Sound Mixing',
      'Music Composition',
      'Hd'
    ],
    vertical: [],
    square: []
  },
  {
    id: '29',
    folder: 'Dutti',
    name: 'Traveller',
    filename: '0774_DuttiTraveller_18_E1D_C1_A1_SubEn',
    client: 'Massimo Dutti',
    director: 'hunter and gatti',
    version: ['1:00'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Sound Mixing',
      'Music Composition',
      'Hd',
      'CC'
    ],
    vertical: ['History', '2', '3', '4'],
    square: ['IG']
  },
  {
    id: '28',
    folder: 'NS_iceland',
    name: 'To the Oceans - The Hope',
    filename: '0773_NS_Iceland_E1E_C1_EN_R1_1',
    client: 'North Sails',
    director: 'hunter and gatti',
    version: ['1:40'],
    work: [
      'Edition',
      'Color Grading',
      'Sound Mixing',
      'Music Composition',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },
  {
    id: '27',
    folder: 'NS_vargas',
    name: 'To the Oceans - The Project',
    filename: '0770_NSails_VargasCanada_E5C_C1',
    client: 'North Sails',
    director: 'hunter and gatti',
    version: ['3:36'],
    work: [
      'Edition',
      'Color Grading',
      'Sound Mixing',
      'Music Composition',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },
  {
    id: '26',
    folder: 'NS_summer',
    name: 'To the Oceans - The Call',
    filename: '0769NorthSails_summer18_E1B_C1_422',
    client: 'North Sails',
    director: 'hunter and gatti',
    version: ['3:36', 'EN', 'IT', '90', '30'],
    work: [
      'Edition',
      'Color Grading',
      'Sound Mixing',
      'Music Composition',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },
  {
    id: '25',
    folder: 'Mng_journeys_VI',
    name: 'Journeys VI Argentina',
    filename: '0763_Journeys_Argentina_E3_FX1',
    client: 'Mango',
    director: 'hunter and gatti',
    version: ['3:54'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Sound Mixing',
      'Music Composition',
      'Hd'
    ],
    vertical: [],
    square: []
  },
  {
    id: '24',
    folder: 'Mng_journeys_V',
    name: 'Journeys V Thailand',
    filename: '0760_Journeys_Thailand_SS17_E9_C1_A1',
    client: 'Mango',
    director: 'hunter and gatti',
    version: ['2:55', 'EN', '20'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Sound Mixing',
      'Music Composition',
      'Hd'
    ],
    vertical: [],
    square: []
  },
  {
    id: '23',
    folder: 'Mng_journeys_IV',
    name: 'Journeys IV New Orleans',
    filename: '0756_JourneysMangoIV_Langley_E8_A1_C1_1920x810_Subs_422',
    client: 'Mango',
    director: 'hunter and gatti',
    version: ['4:59', '20', '6', 'Quickfire'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Sound Mixing',
      'Music Composition',
      'Hd'
    ],
    vertical: [],
    square: []
  },

  {
    id: '22',
    folder: 'Mng_journeys_III',
    name: 'Journeys III Tokyo',
    filename: '0745_MNG_Journeys_ChIII_Alma_Main_E3_C1',
    client: 'Mango',
    director: 'hunter and gatti',
    version: ['4:59', 'EN', 'Interview', 'Quickfire'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Sound Mixing',
      'Music Composition',
      'Hd'
    ],
    vertical: [],
    square: []
  },
  {
    id: '21',
    folder: 'Mng_journeys_II',
    name: 'Journeys II New York',
    filename: '0745_MNG_Journeys_ChII_Julia_E2_C1',
    client: 'Mango',
    director: 'hunter and gatti',
    version: ['2:11', 'EN', '20', 'Interview', 'Quickfire'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Sound Mixing',
      'Music Composition',
      'Hd'
    ],
    vertical: [],
    square: []
  },

  {
    id: '20',
    folder: 'Mng_journeys_I',
    name: 'Journeys I Marfa',
    filename: '0745_MNG_Journeys_CHI_Camille_E4_C3',
    client: 'Mango',
    director: 'hunter and gatti',
    version: ['2:06', 'interview', 'Quickfire'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Sound Mixing',
      'Music Composition',
      'Hd'
    ],
    vertical: [],
    square: []
  },
  {
    id: '19',
    folder: 'Kocca',
    name: 'SS Collection',
    filename: '41_Kocca_SS16_E1_C2_1920x810_HGDir_White3',
    client: 'Kocca',
    director: 'hunter and gatti',
    version: ['1:11'],
    work: ['Edition', 'Color Grading', 'Motion Graphics', 'Sound Mixing', 'Hd'],
    vertical: [],
    square: []
  },

  {
    id: '18',
    folder: 'Dutti',
    name: 'Dutti Press',
    filename: '0752_DuttiPressSS17_E2_C1_1920x1080_422',
    client: 'Massimo Dutti',
    director: 'hunter and gatti',
    version: ['60'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },
  {
    id: '17',
    folder: 'Eral_ss17',
    name: 'SS17 Collection',
    filename: '0762_ERAL_SS17_E5A_60s_C1_OK_1920x810_422',
    client: 'Eral North',
    director: 'hunter and gatti',
    version: ['60', '30', '15'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },
  {
    id: '16',
    folder: 'Eci_summertime',
    name: 'Outfit',
    filename: 'SUMMERTIMEMODA30v2C1',
    client: 'El Corte Ingles',
    director: 'hunter and gatti',
    version: ['30', '20', '10'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd'
    ],
    vertical: [],
    square: []
  },
  {
    id: '15',
    folder: 'Eci_summertime',
    name: 'Swimwear',
    filename: 'SUMMERTIMEBANO45v2C1',
    client: 'El Corte Ingles',
    director: 'hunter and gatti',
    version: ['45', '30', '20', '10'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd'
    ],
    vertical: [],
    square: []
  },
  {
    id: '14',
    folder: 'Fabletics',
    name: 'December Campaign',
    filename: 'FL_2016_KH_DecemberCampaign',
    client: 'Fabletics',
    director: 'hunter and gatti',
    version: ['34'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd'
    ],
    vertical: [],
    square: []
  },
  {
    id: '13',
    folder: 'Fabletics',
    name: 'November Campaign',
    filename: 'FL_2016_KH_NovemberCampaign',
    client: 'Fabletics',
    director: 'hunter and gatti',
    version: ['34'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd'
    ],
    vertical: [],
    square: []
  },
  {
    id: '12',
    folder: 'Fabletics',
    name: 'October Campaign',
    filename: 'FL_2016_KH_OctoberCampaign',
    client: 'Fabletics',
    director: 'hunter and gatti',
    version: ['34'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd'
    ],
    vertical: [],
    square: []
  },
  {
    id: '11',
    folder: 'Kocca',
    name: 'K-Woman',
    filename: 'k_woman_by_kocca',
    client: 'Kocca',
    director: 'hunter and gatti',
    version: ['3:13'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },
  {
    id: '10',
    folder: 'NTW',
    name: 'wolves and forest',
    filename: '0768_NTW_FW19_E3B_60s_S2',
    client: 'Network',
    director: 'hunter and gatti',
    version: [''],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },
  {
    id: '9',
    folder: 'Eci_enfasis',
    name: 'Enfasis Black',
    filename: '0766_ECI_EnfasisBlack18_E3_S7',
    client: 'El Corte Ingles',
    director: 'hunter and gatti',
    version: ['52', '30'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },
  {
    id: '8',
    folder: 'NTW',
    name: 'Ice Skaters',
    filename: '0765_Network_E2A_C1_60s_1',
    client: 'Network',
    director: 'hunter and gatti',
    version: ['60'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },
  {
    id: '7',
    folder: 'Dutti',
    name: 'Hamptons',
    filename: '0749_Dutti_Hamptons_W16_E4_1920x1080_C1_422',
    client: 'Massimo Dutti',
    director: 'hunter and gatti',
    version: ['60'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },
  {
    id: '6',
    folder: 'Dutti',
    name: 'Equestrian SS16',
    filename: '0736_MassimoDutti_Equestrian_SS16_E2_S2_1920x810_1_422',
    client: 'Massimo Dutti',
    director: 'hunter and gatti',
    version: ['60'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },

  {
    id: '5',
    folder: 'Hawkers',
    name: 'Pool Party Steve Aoki',
    filename: '0735_Hawkers_SS16_E5_C2_L2_422',
    client: 'Hawkers',
    director: 'hunter and gatti',
    version: ['55'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },

  {
    id: '4',
    folder: 'Textura',
    name: 'The Film',
    filename: '0737_Textura_SS16_Film_E4_S4_CScopeHYG_422',
    client: 'Textura',
    director: 'hunter and gatti',
    version: ['1:14'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },
  {
    id: '3',
    folder: 'Eralnorth',
    name: 'FW15 Campaign',
    filename: 'ERAL_FW15_58s_1920x810_422',
    client: 'Eral North',
    director: 'hunter and gatti',
    version: ['58', '28'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },
  {
    id: '2',
    folder: 'Dutti',
    name: 'Made to Order',
    filename: 'DuttiMadeToOrder2015_E1Color_422',
    client: 'Massimo Dutti',
    director: 'hunter and gatti',
    version: ['42'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  },

  {
    id: '1',
    folder: 'Kocca',
    name: 'FW13',
    filename: 'KoccaFW13_Edit04_1080p422H&G',
    client: 'Kocca',
    director: 'hunter and gatti',
    version: ['18'],
    work: [
      'Edition',
      'Color Grading',
      'Motion Graphics',
      'Animation',
      'Sound Mixing',
      'Hd',
      'CC'
    ],
    vertical: [],
    square: []
  }
]

export default VideoList

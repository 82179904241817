export const AboutContentList = [
  'We are editors, colorists, directors, 3D artists, motion graphics designers, graphic designers, illustrators, and developers.',
  'We are an online team with a remote collaborative architecture that enables us to deliver media directly to brands, studios, producers, directors, editors, and colorists.',
  'Our team is formed by professionals, creatives, technicians and teachers that give us an unique perspective and style developing new media content.',
  'Tell us your idea and we will make it a reality.'
]

export const AboutTitleList = ['Specialists in digital postproduction for advertising, fashion, and film.',
  'We are a new era in the digital media ecosystem.',
  'We love to tell storys worth telling.',
  'We make it Visual.']

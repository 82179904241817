import React, { useEffect, useState, useRef } from 'react'
import './style.scss'

const PLAYING_DEBOUNCE_TIME = 50
const WAITING_DEBOUNCE_TIME = 200

const VideoPlayer = ({ src, ...props }) => {
  // const [isPlaying, setIsPlaying] = useState(false)
  const [isWaiting, setIsWaiting] = useState(false)

  const isWaitingTimeout = useRef(null)
  const isPlayingTimeout = useRef(null)

  const videoElementRef = useRef()

  useEffect(() => {
    if (!videoElementRef.current) {
      return
    }

    const waitingHandler = () => {
      clearTimeout(isWaitingTimeout.current)

      isWaitingTimeout.current = setTimeout(() => {
        setIsWaiting(true)
        console.log('waiting')
      }, WAITING_DEBOUNCE_TIME)
    }

    const playHandler = () => {
      clearTimeout(isWaitingTimeout.current)
      clearTimeout(isPlayingTimeout.current)

      isPlayingTimeout.current = setTimeout(() => {
        // setIsPlaying(true)
        setIsWaiting(false)
      }, PLAYING_DEBOUNCE_TIME)
    }

    const pauseHandler = () => {
      clearTimeout(isWaitingTimeout.current)
      clearTimeout(isPlayingTimeout.current)

      isPlayingTimeout.current = setTimeout(() => {
        // setIsPlaying(false)
        setIsWaiting(false)
      }, PLAYING_DEBOUNCE_TIME)
    }

    const element = videoElementRef.current

    element.addEventListener('waiting', waitingHandler)
    element.addEventListener('play', playHandler)
    element.addEventListener('playing', playHandler)
    element.addEventListener('pause', pauseHandler)

    // clean up
    return () => {
      clearTimeout(isWaitingTimeout.current)
      clearTimeout(isPlayingTimeout.current)

      element.removeEventListener('waiting', waitingHandler)
      element.removeEventListener('play', playHandler)
      element.removeEventListener('playing', playHandler)
      element.removeEventListener('pause', pauseHandler)
    }
  }, [videoElementRef])

  /* const handlePlayPauseClick = () => {
    if (videoElementRef.current) {
      if (!isPlaying) {
        videoElementRef.current.play()
      }
    }
  } */

  const el = useRef()

  /*

  const [timer, setTimer] = React.useState(4);
  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => clear();
  }, []);

  useEffect(() => {
    if (timer === 0) {
      setTimer("")
      console.log(videoElementRef)
         handlePlayPauseClick()
      clear();
    }
  }, [timer]);
  */
  /* const handleVideoEnded = () => {
    const cursorElement = document.getElementById('modal-video');
    console.log(cursorElement);
    gsap.to(cursorElement,{y:"-100%",duration:1, ease:"expo"} )

  };

dentro del video  onEnded={handleVideoEnded}
 <div className='timer-container'><div className='timer-clock'>{timer}</div></div>

*/

  function loadVideo () {
    console.log('loaded')
    videoElementRef.current.play()
  }
  return (
    <>
      <div className='SimpleVideo' ref={el}>
        <video {...props} key={src} onLoadStart={loadVideo} playsInline controls ref={videoElementRef} className='video-player-moto' src={src} id='video-player' controlsList='nodownload noremoteplayback noplaybackrate foobar' />
        {isWaiting && <div className='loader-container'>Buffering video<span className='SimpleVideo-loader' /></div>}
      </div>
    </>
  )
}

export default VideoPlayer

import { Link } from 'react-router-dom'
import './style.scss'
import { useEffect, useLayoutEffect, useRef } from 'react'
import gsap from 'gsap'
const sectionText = ['be in touch', 'Send us an email']
const contactText = ['For all Project Inquiries', 'For collaboration', 'To be part of the team', 'Download our']
const contactTextMail = ['projects@bapost.online', 'collab@bapost.online', 'remote@bapost.online', 'Brand Brochure']
const contactTitleMail = 'get involved'
const contactSubTitleMail = 'Send us your footage'
const title = 'Bapost - You - Contact '
const directionText = ['Headquarters', 'Street Calvet 36', '08021 BCN (SPAIN)']
const privacy = 'Privacy Policy'
export default function You () {
  useEffect(() => {
    document.title = title
  }, [title])
  const el = useRef()
  const tl = useRef()

  const sel = gsap.utils.selector(el)

  useLayoutEffect(() => {
    // add a box and circle animation to our timeline and play on first render
    console.log('creating timeline')
    tl.current && tl.current.progress(0).kill()
    tl.current = gsap.timeline({ defaults: { ease: 'expo' } })
    // Inicio abrir arriba
      .to(sel('.contact-mail-text-wrapper'), { y: 0, duration: 0.8, delay: 1, stagger: 0.2 })
      .to(sel('.contact-adress-text-container'), { y: 0, duration: 0.8, delay: 1, stagger: 0.2 }, '<')
  }, [])
  return (
    <div className='section-container' ref={el}>
      <div className='section-content-container'>
        <div className='top-spacer'>&nbsp;</div>
        <div className='section-title-container'> <div className='section-title-text-container'><div className='section-title-text'>{sectionText[0]}</div></div>
          <div className='section-subtitle-container'><div className='section-subtitle-text'>{sectionText[1]}</div></div>
        </div>
        <div className='section-title-liner'><div className='section-title-liner-wrap' /></div>
        <div className='section-contact-container'>
          <div className='contact-mail-text'>
            <div className='contact-mail-text-wrapper'>
              <span className='section-content-text'>{contactText[0]}&nbsp;</span><a href='mailto:projects@bapost.online' className='link-text'>{contactTextMail[0]}</a>  <br />
            </div>
          </div>
          <div className='contact-mail-text'>
            <div className='contact-mail-text-wrapper'>
              <span className='section-content-text'>{contactText[1]}&nbsp;</span><a href='mailto:collab@bapost.online' className='link-text'>{contactTextMail[1]}</a>  <br />
            </div>
          </div>
          <div className='contact-mail-text'>
            <div className='contact-mail-text-wrapper'>
              <span className='section-content-text'>{contactText[2]}&nbsp;</span><a href='mailto:remote@bapost.online' className='link-text'>{contactTextMail[2]}</a>  <br /><br />
            </div>
          </div>
        </div>

      </div>
      <div className='contact-bottom-container'>
        <div className='section-title-container'> <div className='section-title-text-container'><div className='section-title-text'>{contactTitleMail}</div></div>
          <div className='section-subtitle-container'><div className='section-subtitle-text'>{contactSubTitleMail}</div></div>
        </div>
        <div className='you-title-liner-wrap' />
        <div className='section-bottom-contact-container'>
          <div className='contact-adress-text-privacy'>
            <div className='contact-adress-text-container'>
              <span className='section-content-text'>{directionText[0]}&nbsp;</span><br />
            </div>
            <div className='contact-adress-text-container'>
              <Link to='/Privacy-Policy'>
                <span className='link-text'>{privacy}</span><br />
              </Link>
            </div>
          </div>
          <div className='contact-adress-text-wrapper'>
            <div className='contact-adress-text-container'>
              <span className='section-content-text'>{directionText[1]}&nbsp;</span><br />
            </div>
          </div>
          <div className='contact-adress-text-wrapper'>
            <div className='contact-adress-text-container'>
              <span className='section-content-text'>{directionText[2]}&nbsp;</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

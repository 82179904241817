import { NavLink, Route, Routes, HashRouter } from 'react-router-dom'

import WorkItem from './components/WorkItem'
import './style.scss'
import Home from './components/Home'
import Work from './components/Work'
import For from './components/For'
import You from './components/You'
import Privacy from './components/Privacy'
import ErrorPage from './components/Error'
import ReactGA from 'react-ga'

const TRACKING_ID = 'UA-XXXXX-X' // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID)

const MainLayout = () => {
  return (
    <>
      <HashRouter>
        <div className='navbar-wrapper'>
          <nav className='navbar-container'>
            <div className='navbar-menu-item'>
              <NavLink to='/' className='navbar-text' activeclassname='active'>
                <span>B</span><span style={{ letterSpacing: '-0.09em' }}>a</span><span>post</span>
              </NavLink>
            </div>
            <div className='navbar-menu-item'>
              <NavLink to='/Works' className='navbar-text'>Works</NavLink>
            </div>
            <div className='navbar-menu-item'>
              <NavLink to='/For' className='navbar-text'>For</NavLink>
            </div>
            <div className='navbar-menu-item'>
              <NavLink to='/You' className='navbar-text'>You</NavLink>
            </div>
          </nav>
          <div className='navbar-liner' />
        </div>
        <Routes>
          <Route path='/' element={<Home title='Bapost' />} />
          <Route path='/Works/:workId' element={<WorkItem title=':workId' />} />
          <Route path='/Works' element={<Work title='Bapost' />} />
          <Route path='/Contact' element={<Work />} />
          <Route path='/For' element={<For />} />
          <Route path='/You' element={<You />} />
          <Route path='/Privacy-Policy' element={<Privacy />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </HashRouter>
    </>
  )
}
export default MainLayout

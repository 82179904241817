import './style.scss'
import { useEffect } from 'react'
import PrivacyText from '../Data/PrivacyText'

const title = 'Bapost - Privacy Policy'

export default function Privacy () {
  useEffect(() => {
    document.title = title
  }, [title])

  return (
    <>
      <div className='section-container'>
        <div className='section-content-with-scroll-container'>
          <div className='top-spacer'>&nbsp;</div>
          <div className='section-title-container-scroll'>
            <div className='section-title-text-container'>
              <div className='section-title-text'>Privacy Policy</div>
            </div>
            <div className='section-subtitle-container'>
              <div className='section-subtitle-text'>disclaimer</div>
            </div>
          </div>
          <div className='section-title-liner'><div className='section-title-liner-wrap-scroll' /></div>
          <div className='section-privacy-container'>
            <PrivacyText />
          </div>
        </div>
        <div className='scroll-mask' />
      </div>
    </>
  )
}

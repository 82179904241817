export default function PrivacyText () {
  return (
    <>
      <div className='privacy-text'><p className='highlight-text'>I. PRIVACY AND DATA PROTECTION POLICY</p></div>

      <div className='privacy-text'><p className='normal-text'>Respecting the provisions of current legislation, Bapost (hereinafter, also the Website) undertakes to adopt the necessary technical and organizational measures, according to the appropriate level of security for the risk of the collected data.</p></div>

      <div className='privacy-text'><p className='highlight-text'> Laws incorporated into this privacy policy</p></div>

      <div className='privacy-text'>
        <p className='normal-text'>
          This privacy policy is adapted to the current Spanish and European regulations on the protection of personal data on the internet. Specifically, it complies with the following rules:
          <br /><br />
          Regulation (EU) 2016/679 of the European Parliament and of the Council, of 27 April 2016, on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (GDPR). <br /><br />
          Organic Law 3/2018, of December 5, on the Protection of Personal Data and guarantee of digital rights (LOPD-GDD). <br /><br />
          Royal Decree 1720/2007, of December 21, approving the Regulation for the development of Organic Law 15/1999, of December 13, on the Protection of Personal Data (RDLOPD). <br /><br />
          Law 34/2002, of July 11, on Services of the Information Society and Electronic Commerce (LSSI-CE).
        </p>
      </div>

      <div className='privacy-text'><p className='normal-text'> Identity of the person responsible for the processing of personal data</p></div>

      <div className='privacy-text'>
        <p className='normal-text'>
          The person responsible for the processing of personal data collected at Bapost is: Massimo C&eacute;spedes, with ID: X2156141Y (hereinafter, the Controller). Their contact details are as follows: <br /><br />
          Address: Calle Calvet 36-38 2&ordm; 4&ordf; 08021 Barcelona (Spain)<br /><br />
          Contact phone: 687306625 Contact email: contact@bapost.online
        </p>
      </div>

      <div className='privacy-text'><p className='highlight-text'>Registration of Personal Data</p></div>

      <div className='privacy-text'>
        <p className='normal-text'>
          In compliance with the provisions of the GDPR and the LOPD-GDD, we inform you that the personal data collected by Loft2Live through the forms on its pages will be incorporated and processed in our files in order to facilitate, streamline, and fulfill the commitments established between Bapost and the User, or to maintain the relationship established in the forms that the User fills out, or to respond to a request or query from the User. Likewise, in accordance with the provisions of the GDPR and the LOPD-GDD, unless the exception provided for in Article 30.5 of the GDPR applies, a record of processing activities is maintained that specifies, according to its purposes, the processing activities carried out and the other circumstances established in the GDPR.<br /><br /> The processing of the User's personal data shall be subject to the following principles set out in Article 5 of the GDPR and in Article 4 and following of the Organic Law 3/2018, of December 5, on the Protection of Personal Data and guarantee of digital rights: <br /><br />
          Principle of lawfulness, fairness and transparency: the prior consent of the User will always be required, preceded by completely transparent information on the purposes for which personal data are collected. <br /><br />
          Principle of purpose limitation: personal data will be collected for specific, explicit, and legitimate purposes. <br /><br />
          Principle of data minimization: the personal data collected will be only those strictly necessary in relation to the purposes for which they are processed. Principle of accuracy: personal data must be accurate and kept up to date. Principle of storage limitation: personal data will only be kept in a form that allows the identification of the User for the time necessary for the purposes of their processing. Principle of integrity and confidentiality: personal data will be processed in a way that ensures their security and confidentiality. <br /><br />
          Principle of proactive responsibility: the Controller shall be responsible for ensuring that the above principles are complied with.
        </p>
      </div>

      <div className='privacy-text'><p className='highlight-text'> Categories of personal data</p></div>

      <div className='privacy-text'><p className='normal-text'>The categories of data processed at Bapost are solely identifying data. Under no circumstances are special categories of personal data as defined in Article 9 of the GDPR processed. Legal basis for the processing of personal data The legal basis for the processing of personal data is consent. Bapost undertakes to obtain the express and verifiable consent of the User for the processing of their personal data for one or more specific purposes. The User shall have the right to withdraw their consent at any time. Withdrawing consent shall be as easy as giving it. As a general rule, withdrawing consent shall not condition the use of the Website. In cases where the User must or may provide their data through forms to make inquiries, request information, or for reasons related to the content of the Website, they shall be informed if the completion of any of them is mandatory because they are essential for the proper development of the operation carried out.</p></div>

      <div className='privacy-text'><p className='highlight-text'> Purposes of the processing of personal data</p></div>

      <div className='privacy-text'>
        <p className='normal-text'>
          Bapost collects and manages personal data for the purpose of facilitating, expediting, and fulfilling the commitments established between the Website and the User, or maintaining the relationship established in the forms that the latter fills out, or to respond to a request or inquiry.
          <br /><br />Likewise, the data may be used for commercial purposes of personalization, operation, and statistics, and activities related to the corporate purpose of Bapost, as well as for the extraction, storage of data, and marketing studies to adapt the Content offered to the User, as well as to improve the quality, functionality, and navigation of the Website.
          <br /><br />At the time the personal data is obtained, the User will be informed about the specific purpose or purposes of the processing to which the personal data will be destined; that is, the use or uses that will be given to the collected information.
        </p>
      </div>

      <div className='privacy-text'><p className='highlight-text'> Retention periods of personal data</p></div>

      <div className='privacy-text'>
        <p className='normal-text'>
          Personal data will only be retained for the minimum time necessary for their treatment and, in any case, only for the following period: 5 years, or until the User requests their deletion.<br /><br />
          At the time the personal data is obtained, the User will be informed about the period during which the personal data will be retained or, when this is not possible, the criteria used to determine this period.
        </p>
      </div>

      <div className='privacy-text'><p className='highlight-text'> Recipients of personal data</p></div>

      <div className='privacy-text'><p className='normal-text'>The User's personal data will not be shared with third parties. In any case, at the time the personal data is obtained, the User will be informed about the recipients or categories of recipients of the personal data.</p></div>

      <div className='privacy-text'><p className='highlight-text'> Personal data of minors</p></div>

      <div className='privacy-text'><p className='normal-text'>Respecting what is established in Articles 8 of the GDPR and 7 of Organic Law 3/2018, of December 5, on the Protection of Personal Data and guarantee of digital rights, only those over 14 years of age may give their consent for the lawful processing of their personal data by Bapost. If it is a minor under 14 years of age, the consent of their parents or guardians will be necessary for the processing, and it will only be considered lawful to the extent that they have authorized it.</p></div>

      <div className='privacy-text'><p className='highlight-text'> Confidentiality and security of personal data</p></div>

      <div className='privacy-text'>
        <p className='normal-text'>
          Bapost undertakes to adopt the necessary technical and organizational measures, according to the appropriate level of security for the risk of the collected data, so that the security of personal data is guaranteed and the accidental or unlawful destruction, loss, alteration, or unauthorized communication or access to such data transmitted, stored or otherwise processed is prevented.<br /><br />
          However, since Bapost cannot guarantee the impregnability of the Internet or the total absence of hackers or others who fraudulently access personal data, the data controller undertakes to communicate to the User without undue delay when there is a security breach of personal data that is likely to pose a high risk to the rights and freedoms of natural persons. Following what is established in Article 4 of the GDPR, a security breach of personal data is understood as any breach of security that causes accidental or unlawful destruction, loss, alteration, unauthorized communication or access to personal data transmitted, stored or otherwise processed. <br /><br />
          The personal data will be treated as confidential by the data controller, who undertakes to inform and ensure, by means of a legal or contractual obligation, that such confidentiality is respected by its employees, associates, and any person to whom the information is made accessible.
        </p>
      </div>

      <div className='privacy-text'><p className='highlight-text'>Rights derived from the processing of personal data</p></div>

      <div className='privacy-text'>
        <p className='normal-text'>
          The User has the following rights recognized in the GDPR and in the Organic Law 3/2018, of December 5, on the Protection of Personal Data and guarantee of digital rights, with respect to Bapost and may, therefore, exercise them against the Controller of the processing: <br /><br />
          Right of access: It is the User's right to obtain confirmation from Bapost on whether or not their personal data is being processed and, if so, to obtain information on their specific personal data and the processing that Bapost has carried out or is carrying out, as well as, among other things, information available on the origin of such data and the recipients of the communications made or planned with them. <br /><br />
          Right of rectification: It is the User's right to have their personal data corrected if it is inaccurate or incomplete, taking into account the purposes of the processing.<br /><br />
          Right to erasure (&quot;right to be forgotten&quot;): It is the User's right, provided that current legislation does not establish otherwise, to obtain the erasure of their personal data when it is no longer necessary for the purposes for which it was collected or processed; the User has withdrawn their consent to the processing and there is no other legal basis for it; the User objects to the processing and there is no other legitimate reason to continue it; the personal data has been processed unlawfully; the personal data must be deleted in compliance with a legal obligation; or the personal data has been obtained as a result of a direct offer of information society services to a child under 14 years of age. In addition to erasing the data, the Controller, taking into account the available technology and the cost of its implementation, must take reasonable measures to inform the controllers who are processing the personal data of the data subject's request to delete any link to that personal data. <br /><br />
          Right to restriction of processing: It is the User's right to restrict the processing of their personal data. The User has the right to obtain the restriction of processing when they contest the accuracy of their personal data; the processing is unlawful; the Controller no longer needs the personal data, but the User needs it for the establishment, exercise, or defense of legal claims; or the User has objected to the processing.<br /><br />
          Right to data portability: Where processing is carried out by automated means, the User shall have the right to receive their personal data from the Controller in a structured, commonly used, and machine-readable format and to transmit it to another Controller. Where technically feasible, the Controller shall transmit the data directly to that other Controller. <br /><br />Right to object: It is the User's right not to have their personal data processed by Bapost or to have the processing of their data ceased. <br /><br />
          Right not to be subject to a decision based solely on automated processing, including profiling: It is the User's right not to be subject to an individualized decision based solely on automated processing of their personal data, including profiling, except as provided by applicable law.
        </p>
      </div>

      <div className='privacy-text'><p className='normal-text'>Therefore, the User may exercise their rights by written communication addressed to the Data Controller with the reference &quot;GDPR-Bapost&quot;, specifying:</p></div>

      <div className='privacy-text'>
        <p className='normal-text'>Name, surname of the User and a copy of their ID. In cases where representation is allowed, identification of the person representing the User by the same means, as well as the accrediting document of the representation, will also be necessary. The photocopy of the ID may be replaced by any other valid means of law that verifies the identity. <br /><br />
          Request with specific reasons for the request or information that the User wants to access. Address for notification purposes. Date and signature of the requester. Any document that verifies the request being made.
        </p>
      </div>

      <div className='privacy-text'><p className='normal-text'>This request and any other attached documents may be sent to the following address and/or email:</p></div>

      <div className='privacy-text'><p className='normal-text'>Postal address: Calle Calvet 36 08021 Barcelona (SPAIN) Email: contact@bapost.online.com</p></div>

      <div className='privacy-text'><p className='highlight-text'>Complaints to the supervisory authority</p></div>

      <div className='privacy-text'><p className='normal-text'>If the User considers that there is a problem or infringement of the current regulations in the way their personal data is being processed, they will have the right to effective judicial protection and to file a complaint with a supervisory authority, in particular, in the state where they have their habitual residence, place of work or place of the alleged infringement. In the case of Spain, the supervisory authority is the Spanish Data Protection Agency (http://www.agpd.es).</p></div>

      <div className='privacy-text'><p className='highlight-text'>II. COOKIE POLICY</p></div>

      <div className='privacy-text'>
        <p className='normal-text'>
          Accessing this Website may involve the use of cookies. Cookies are small amounts of information that are stored in the browser used by each User - on the different devices they may use to navigate - so that the server remembers certain information that only the server that implemented it will subsequently and exclusively read. Cookies facilitate navigation, make it more user-friendly, and do not damage the browsing device. <br /><br />
          Cookies are automatic procedures for collecting information regarding the preferences determined by the User during their visit to the Website in order to recognize them as a User, personalize their experience and use of the Website, and may also, for example, help identify and resolve errors<br /><br />The information collected through cookies may include the date and time of visits to the Website, the pages viewed, the time spent on the Website, and the sites visited just before and after it. However, no cookie allows it to contact the User's telephone number or any other personal contact method. No cookie can extract information from the User's hard drive or steal personal information. The only way for the User's private information to become part of the Cookie file is for the user to personally provide that information to the server. <br /><br />
          Cookies that allow a person to be identified are considered personal data. Therefore, the Privacy Policy described above will apply to them. In this sense, the User's consent will be necessary for their use. This consent will be communicated based on an authentic choice, offered through an affirmative and positive decision, prior to initial, removable, and documented processing.
        </p>
      </div>

      <div className='privacy-text'><p className='highlight-text'>Own cookies</p></div>

      <div className='privacy-text'>
        <p className='normal-text'>
          These are cookies that are sent to the User's computer or device and managed exclusively by Bapost for the better functioning of the Website. The information collected is used to improve the quality of the Website and its Content and the User's experience. These cookies allow the User to be recognized as a recurring visitor to the Website and to adapt the content to offer content that fits their preferences. <br /><br />
          The entity/entities responsible for supplying cookies may transfer this information to third parties, provided that the law requires it or a third party processes this information for these entities.
        </p>
      </div>

      <div className='privacy-text'><p className='highlight-text'>Disabling, rejecting, and deleting cookies</p></div>

      <div className='privacy-text'>
        <p className='normal-text'>
          The User can disable, reject, and delete cookies - totally or partially - installed on their device by configuring their browser (including, for example, Chrome, Firefox, Safari, Explorer). In this regard, the procedures for rejecting and deleting cookies may differ from one Internet browser to another. Therefore, the User should consult the instructions provided by the Internet browser they are using. In the event that they reject the use of cookies - totally or partially - they may continue to use the Website, although they may have limited use of some of its features.
        </p>
      </div>

      <div className='privacy-text'><p className='highlight-text'>III. ACCEPTANCE AND CHANGES TO THIS PRIVACY AND COOKIE POLICY</p></div>

      <div className='privacy-text'>
        <p className='normal-text'>
          It is necessary for the User to have read and agree to the conditions regarding the protection of personal data contained in this Privacy and Cookie Policy, as well as to accept the processing of their personal data so that the data controller can proceed with it in the manner, during the periods, and for the purposes indicated. The use of the Website implies acceptance of its Privacy and Cookie Policy. <br /><br />
          Bapost reserves the right to modify its Privacy and Cookie Policy, according to its own criteria, or motivated by a legislative, jurisprudential, or doctrinal change from the Spanish Data Protection Agency. Changes or updates to this Privacy and Cookie Policy will be explicitly notified to the User. <br /><br />
          This Privacy and Cookie Policy was updated on January 10, 2022, to comply with Regulation (EU) 2016/679 of the European Parliament and of the Council of April 27, 2016, on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (GDPR) and Organic Law 3/2018,
        </p>
      </div>
    </>
  )
}

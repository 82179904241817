import React, { useEffect, createRef, useRef, useState, useLayoutEffect } from 'react'
import './style.scss'
import gsap from 'gsap'
import lottie from 'lottie-web'
import animation from '../animations/intro_logo_bapost.json'
import MotiontextHome from '../MotiontextHome'

import { GoPlus } from 'react-icons/go'

const animationContainer = createRef()

export function MotionMaskHome () {
  const [isMask, setIsMask] = useState(true)
  const cssPercentaje = 50
  const screenWidthPencentaje = Math.round((window.innerWidth * cssPercentaje) / 100)

  const logoWidth = 1275
  const logoHeight = 250
  const logoHConversion = Math.round(screenWidthPencentaje * logoHeight / logoWidth) / 2
  const logoWConversion = Math.round(screenWidthPencentaje) / 2
  const [showText, setShowText] = useState(false)

  function ShowTextFunc () {
    setShowText(true)
    setIsMask(false)
  }

  // ANIM SVG
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: animation

    })
    anim.onComplete = function (e) {
      console.log('loading text')
      ShowTextFunc()
    }

    return () => anim.destroy() // optional clean up for unmounting
  }, [])

  // Calculo logo presabiendo tamaño

  // ANIM GSAP

  const el = useRef()
  const tl = useRef()

  const sel = gsap.utils.selector(el)

  useLayoutEffect(() => {
    // add a box and circle animation to our timeline and play on first render
    console.log('creating timeline')
    tl.current && tl.current.progress(0).kill()
    tl.current = gsap.timeline({ defaults: { ease: 'expo' } })
    // Inicio abrir arriba
      .to(sel('.top-half'), { y: '-10px', duration: 0.2, delay: 0.1 })
      .to(sel('.bottom-half'), { y: '10px' }, '<')
    // abrir lados
      .to(sel('.left-half'), { x: -logoWConversion, duration: 0.4, delay: 0.1 })
      .to(sel('.right-half'), { x: logoWConversion }, '<')
    // abrir logo
      .to(sel('.top-half'), { y: -logoHConversion, duration: 0.5, delay: 0.15 })
      .to(sel('.bottom-half'), { y: logoHConversion }, '<')

      .to(sel('.left-half'), { x: -window.innerWidth / 2, duration: 0.5, delay: 0.7 })
      .to(sel('.right-half'), { x: window.innerWidth / 2 }, '<')
      .to(sel('.top-half'), { y: -window.innerHeight / 2 }, '<')
      .to(sel('.bottom-half'), { y: window.innerHeight / 2 }, '<')
  }, [])
  /// /------------------------ Resize ventana

  return (
    <>
      {isMask &&
        <>
          <div className='home-motion-mask-container' ref={el}>
            <div className='top-left-container'>
              <GoPlus className='ico' />
            </div>
            <div className='top-right-container'>
              <GoPlus className='ico' />
            </div>
            <div className='bottom-right-container'>
              <GoPlus className='ico' />
            </div>
            <div className='bottom-left-container'>
              <GoPlus className='ico' />
            </div>
            <div className='hor-line-half'>
              <div className='top-half' />
              <div className='bottom-half' />
            </div>
            <div className='vert-line-half'>
              <div className='left-half' />
              <div className='right-half' />
            </div>
            <div className='logo-bapost-container'>
              <div className='logo-bapost' ref={animationContainer} />
            </div>
          </div>
        </>}
      {showText && <MotiontextHome />}
    </>
  )
}

// import { useRouteError } from 'react-router-dom'
import './style.scss'
const sectionText = ['Error', '404 page not found']
const sectionsubText = ['Something went wrong', 'The page you are looking does not exists']

export default function ErrorPage () {
// const error = useRouteError()
//  console.error(error)

  return (
    <div className='section-container'>
      <div className='section-content-container'>
        <div className='top-spacer'>&nbsp;</div>
        <div className='section-title-container'>
          <div className='section-title-text-container'>
            <div className='section-title-text'>{sectionText[0]}</div>
          </div>
          <div className='section-subtitle-container'>
            <div className='section-subtitle-text'>{sectionText[1]}</div>
          </div>
        </div>
        <div className='section-title-liner'><div className='section-title-liner-wrap'>&nbsp;</div></div>
        <div className='section-error-container'>
          <span className='highlight-text'>{sectionsubText[0]}&nbsp;<br /></span><span className='section-content-text'>{sectionsubText[1]}</span>
          <div className='bottom-spacer'>&nbsp;</div>
        </div>
      </div>
    </div>
  )
}

import { useEffect, useState, useRef, useCallback } from 'react'

import './style.scss'
import textAnimBapost from '../Data/textAnimBapost'
import gsap from 'gsap'

const shortTextAnim = [['Your', 'Remote'], ['For', 'You'], ['For', 'Your']]

const screenWidth = window.innerWidth
// let screenHeight = window.innerHeight
// let calcH = screenHeight;
const calcW = screenWidth

export default function MotiontextHome () {
  // const index = Math.floor(Math.random() * 3)
  // const subindex = Math.floor(Math.random() * textAnimBapost[index].length)

  // const [count, setCount] = useState(0);

  const [isText1, setText1] = useState('Bapost')
  const [isText1b, setText1b] = useState('Remote')
  const [isText2, setText2] = useState('Studio')
  const [isText2b, setText2b] = useState('Post')
  const [isText3, setText3] = useState('By Pros')

  /// ---------funcion cambio de texto
  useEffect(() => {
    animTextFunc()
  }, [])

  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * 3)
    const subindex = Math.floor(Math.random() * textAnimBapost[index].length)
    setText1('We')
    setText1b('Are')
    setText2(shortTextAnim[index][0])
    setText2b(shortTextAnim[index][1])
    setText3(textAnimBapost[index][subindex])
    animTextFunc()
  }, [])

  useEffect(() => {
    const intervalID = setInterval(shuffle, 7000)
    return () => clearInterval(intervalID)
  }, [shuffle])

  // gsap anim text

  const el = useRef()
  const tl = useRef()

  const sel = gsap.utils.selector(el)

  function animTextFunc () {
    const random = gsap.utils.random(-window.innerWidth / 6, window.innerWidth / 6, 10, true)
    // /smaller more centered
    console.log('creating timeline')
    console.log(-window.innerWidth / 5)
    tl.current && tl.current.progress(0).kill()
    tl.current = gsap.timeline({ defaults: { ease: 'expo' } })
    // Inicio mover caja y bajar texto

      .fromTo(sel('.motion-text-item1'), { x: window.innerWidth }, { x: random(), duration: 0.2 })
      .to(sel('.text-1'), { y: '0', duration: 0.2 })

      .fromTo(sel('.motion-text-item1b'), { x: window.innerWidth + random() }, { x: random(), duration: 0.2 })
      .to(sel('.text-1b'), { y: '0', duration: 0.2 })

      .fromTo(sel('.motion-text-item2'), { x: window.innerWidth + random() }, { x: random(), duration: 0.2 })
      .to(sel('.text-2'), { y: '0', duration: 0.2 })

      .fromTo(sel('.motion-text-item2b'), { x: window.innerWidth + random() }, { x: random(), duration: 0.2 })
      .to(sel('.text-2b'), { y: '0', duration: 0.2 })

      .fromTo(sel('.motion-text-item3'), { x: window.innerWidth + 800 }, { x: '0', duration: 0.2 })
      .to(sel('.text-3'), { y: '0', duration: 0.2 })

    // pause moment----------------------------------

      .to(sel('.motion-text-item1'), { x: -window.innerWidth, duration: 0.2, delay: 3 })
      .to(sel('.text-1'), { y: '100%', duration: 0.2 })

      .to(sel('.motion-text-item1b'), { x: -window.innerWidth, duration: 0.2 }, '-=0.2')
      .to(sel('.text-1b'), { y: '100%', duration: 0.2 })

      .to(sel('.motion-text-item2'), { x: -window.innerWidth, duration: 0.2 }, '-=0.2')
      .to(sel('.text-2'), { y: '100%', duration: 0.2 })

      .to(sel('.motion-text-item2b'), { x: -window.innerWidth, duration: 0.2 }, '-=0.2')
      .to(sel('.text-2b'), { y: '100%', duration: 0.2 })

      .to(sel('.motion-text-item3'), { x: -window.innerWidth, duration: 0.2 }, '-=0.2')
      .to(sel('.text-3'), { y: '100%', duration: 0.2 })
  }

  /// /------------------------ Resize ventana

  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  const handleWindowSizeChange = () => {
    console.log('resizing')
    console.log(calcW)
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [width, height])

  return (
    <>
      <div className='home-motion-container' ref={el}>
        <div className='home-motion-wrapper'>
          <div className='motion-text-item1'>
            <div className='text-1'><span className='intro-anim-text'>{isText1}</span></div>
          </div>
          <div className='motion-text-item1b'>
            <div className='text-1b'><span className='intro-anim-text'>{isText1b}</span></div>
          </div>
          <div className='motion-text-item2'>
            <div className='text-2'><span className='intro-anim-text'>{isText2}</span></div>
          </div>
          <div className='motion-text-item2b'>
            <div className='text-2b'><span className='intro-anim-text'>{isText2b}</span></div>
          </div>
          <div className='motion-text-item3'>
            <div className='text-3'><span className='intro-anim-text'>{isText3}</span></div>
          </div>
        </div>
      </div>
    </>
  )
}

import './style.scss'
import { useEffect, useLayoutEffect, useRef } from 'react'
import gsap from 'gsap'
import { AboutContentList, AboutTitleList } from '../Data/about_list'
const title = 'Bapost - For - About '

export default function For () {
  useEffect(() => {
    document.title = title
  }, [title])
  const slides = []
  for (let i = 0; i < AboutTitleList.length; i++) {
    slides.push(
      <div key={`slideManifesto-${i}`} className='section-slide-item-wrapper'>
        <div className='section-slide-item-container'>
          <div className='for-title-container'>
            <div className='for-slide-title-text'>{AboutTitleList[i]}</div>
          </div>
          <div className='for-spacer-scroll-line' />
          <div className='for-subtitle-container'>
            <div className='for-slide-subtitle-text'>{AboutContentList[i]}</div>
          </div>
        </div>
      </div>
    )
  }

  const el = useRef()
  const tl = useRef()

  const sel = gsap.utils.selector(el)

  useLayoutEffect(() => {
    // add a box and circle animation to our timeline and play on first render
    console.log('creating timeline')
    tl.current && tl.current.progress(0).kill()
    tl.current = gsap.timeline({ defaults: { ease: 'expo' } })
    // Inicio abrir arriba
      .to(sel('.work-list-item'), { x: '0', duration: 0.3, delay: 0.2, stagger: 0.2 })
      .to(sel('.slide-content-container'), { y: '0', duration: 0.3, delay: 0.2, stagger: 0.3 }, '<')
  }, [])

  return (
    <>
      <div className='section-container' ref={el}>
        <div className='section-content-with-scroll-container'>
          <div className='top-spacer'>&nbsp;</div>
          <div className='section-title-container-scroll'>
            <div className='section-title-text-container'>
              <div className='section-title-text'>Remote Team</div>
            </div>
            <div className='section-subtitle-container'>
              <div className='section-subtitle-text'>workflow</div>
            </div>
          </div>
          <div className='section-title-liner'><div className='section-title-liner-wrap-scroll'>&nbsp;</div></div>
          <div className='section-for-container'>
            {slides}
          </div>
        </div>
        <div className='scroll-mask' />
      </div>
    </>
  )
}

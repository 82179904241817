import { useState } from 'react'
import ErrorPage from '../Error'

import './style.scss'
import VideoList from '../Data/VideoList'
import VideoPlayerItem from '../VideoPlayerItem'
// import gsap from 'gsap'

import { FaMobileAlt, FaDesktop, FaInstagramSquare } from 'react-icons/fa'
import { RiScissorsCutFill } from 'react-icons/ri'
import {
  MdHd,
  MdColorLens,
  MdClosedCaption,
  MdAnimation,
  MdClose,
  MdAutoFixHigh,
  MdHeadphones,
  MdFormatClear,
  MdDevices,
  Md4K
} from 'react-icons/md'
import { BsSoundwave } from 'react-icons/bs'

import { useParams, Link } from 'react-router-dom'

const url = 'https://bapost.online/static/media/videos/'

// funcion Iconos cada trabajo
function IcoList ({ number }) {
  const n = VideoList[number].work.length

  // Slice function call
  const items = VideoList[number].work.slice(0, n).map((i) => {
    if (i === 'Directing') {
      return (
        <div className='icono-ico' key={`icos-${i}`} title='Edition'>
          <RiScissorsCutFill title='Directing' />
        </div>
      )
    }
    if (i === 'Edition') {
      return (
        <div className='icono-ico' key={`icos-${i}`} title='Edition'>
          <RiScissorsCutFill title='Edition' />
        </div>
      )
    }
    if (i === 'Color Grading') {
      return (
        <div className='icono-ico' key={`icos-${i}`} title='Color Grading'>
          <MdColorLens title='Color Grading' />
        </div>
      )
    }
    if (i === 'Sound Mixing') {
      return (
        <div className='icono-ico' key={`icos-${i}`}>
          <MdHeadphones title='Sound Mixing' />
        </div>
      )
    }
    if (i === 'Music Composition') {
      return (
        <div className='icono-ico' key={`icos-${i}`} title='Sound Fx'>
          <BsSoundwave title='Music Composition' />
        </div>
      )
    }
    if (i === 'Vfx') {
      return (
        <div className='icono-ico' key={`icos-${i}`} title='Vfx'>
          <MdAutoFixHigh title='Vfx' />
        </div>
      )
    }
    if (i === 'Motion Graphics') {
      return (
        <div className='icono-ico' key={`icos-${i}`} title='Motion Graphics'>
          <MdFormatClear title='Motion Graphics' />
        </div>
      )
    }
    if (i === 'Animation') {
      return (
        <div className='icono-ico' key={`icos-${i}`} title='Animation'>
          <MdAnimation title='Animation' />
        </div>
      )
    }
    if (i === 'Hd') {
      return (
        <div className='icono-ico' key={`icos-${i}`} title='Hd'>
          <MdHd title='Hd' />
        </div>
      )
    }
    if (i === '4k') {
      return (
        <div className='icono-ico' key={`icos-${i}`} title='4K'>
          <Md4K title='4K' />
        </div>
      )
    }
    if (i === 'CC') {
      return (
        <div className='icono-ico' key={`icos-${i}`} title='CC'>
          <MdClosedCaption title='CC' />
        </div>
      )
    }
    if (i === 'Formats') {
      return (
        <div className='icono-ico' key={`icos-${i}`} title='Various Devices'>
          <MdDevices title='Various Devices' />
        </div>
      )
    } else {
      return <div className='icono-ico' key={`icos-${i}`} />
    }
  })

  return (
    <div className='item-item-iconos-container' key={`icono-${number}`}>
      {items}
    </div>
  )
}

// -----------------------------------------------------------------------------------
// default function
export default function WorkItem (works) {
  // const [isModalVis, setModalVis] = useState(false)
  // const [isVideo, setVideo] = useState(0)

  function PreviewList ({ number }) {
    const n = VideoList[number].version.length
    const items = VideoList[number].version.slice(0, n).map((i) => {
      return (
        <div
          key={i + 'prevlist' + number}
          className='work-preview-text'
          onClick={() =>
            handleClick2(number, VideoList[number].version.indexOf(i), 0)}
        >
          {i} &nbsp;/&nbsp;
        </div>
      )
    })
    return (
      <>
        <FaDesktop className='preview-ico' />
        {items}
      </>
    )
  }

  function PreviewListVert ({ number }) {
    const n = VideoList[number].vertical.length
    if (VideoList[number].vertical.length > 0) {
      const items = VideoList[number].vertical.slice(0, n).map((i) => {
        return (
          <div
            key={i + 'vertlist' + number + i}
            className='work-preview-text'
            onClick={() =>
              handleClick2(number, VideoList[number].vertical.indexOf(i), 1)}
          >
            {i}&nbsp;/&nbsp;

          </div>
        )
      })
      return (
        <>
          <FaMobileAlt className='preview-ico' />
          {items}
        </>
      )
    } else {
      return <></>
    }
  }

  function PreviewListSquare ({ number }) {
    const n = VideoList[number].square.length
    if (VideoList[number].square.length > 0) {
      const items = VideoList[number].square.slice(0, n).map((i) => {
        return (
          <div
            key={i + 'squarelist' + number + i}
            className='work-preview-text'
            onClick={() =>
              handleClick2(number, VideoList[number].square.indexOf(i), 2)}
          >
            {i} &nbsp;/&nbsp;

          </div>
        )
      })
      return (
        <>
          <FaInstagramSquare className='preview-ico' />
          {items}
        </>
      )
    } else {
      return <></>
    }
  }

  const handleClick2 = (video, version, format) => {
    if (format === -1) {
      console.log('-1 format ')
    }
    if (format === 0) {
      if (version === 0) {
        //   setVersion(0)
        setUrl(
          url +
            VideoList[video].folder +
            '/' +
            VideoList[video].filename +
          '.mp4'
        )
      } else {
        setUrl(
          url +
            VideoList[video].folder +
            '/Ver/' +
            VideoList[video].filename +
            '_' +
            VideoList[video].version[version] +
            '.mp4'
        )
      }
    }
    if (format === 1) {
      if (version === 0) {
        //   setVersion(0)
        // setVideo(video)
        setUrl(
          url +
          VideoList[video].folder +
          '/Ver/' +
            VideoList[video].filename +
          '_vert.mp4'
        )
      } else {
        setUrl(
          url +
            VideoList[video].folder +
            '/Ver/' +
            VideoList[video].filename +
            '_vert' +
            VideoList[video].vertical[version] +
            '.mp4'
        )
      }
    }
    if (format === 2) {
      if (version === 0) {
        //   setVersion(0)
        // setVideo(video)
        setUrl(
          url +
            VideoList[video].folder +
            '/Ver/' +
            VideoList[video].filename +
          '_square.mp4'
        )
      } else {
        setUrl(
          url +
            VideoList[video].folder +
            '/Ver/' +
            VideoList[video].filename +
            '_square' +
            VideoList[video].square[version] +
            '.mp4'
        )
      }
    }
  }

  const { workId } = useParams()
  const WordSeparator = workId.lastIndexOf('$')
  const IndexRead = workId.slice(WordSeparator + 1, workId.length)
  const IndexConvert = VideoList.length - IndexRead
  const work = VideoList.find((work) => work.id === IndexRead)
  console.log(work)
  console.log(work.filename)
  // const [modalVideo, setModalVideo] = useState(0)
  const url0 = url + work.folder + '/' + work.filename + '.mp4'

  const [isUrl, setUrl] = useState(url0)
  if (!work) {
    return <ErrorPage src={isUrl} />
  } else {
    document.title = 'Bapost - ' + work.name + ' by ' + work.director + ' for ' + work.client
  }

  return (
    <>
      <div className='section-container'>
        <div className='section-content-container'>
          <div className='top-spacer'>&nbsp;</div>
          <div className='section-title-container'>
            <div className='section-title-text-container'>
              <div className='section-title-text'>Video Detail</div>
            </div>
            <div className='section-subtitle-container'>
              <div className='section-subtitle-text'>
                <span className='close-item-item'>
                  <Link to='/Works'>
                    <MdClose className='workitem-close-x' />
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className='section-title-liner'><div className='section-title-liner-wrap' /></div>
          <div className='section-workitem-container'>
            <div className='section-workitem-wrapper'>
              <div className='item-liner-top' />
              <div className='workitem-title-container'>
                <div className='detail-item-title-wrapper'>
                  <span className='detail-item-text-number'>
                    {IndexRead}&nbsp;
                  </span>
                  <span className='detail-item-text-title'>
                    {work.name}
                  </span>
                </div>
              </div>
              <div className='item-liner-top2' />
              <div className='workitem-video-container'>
                <div className='work-list-line-preview-ico'>
                  <VideoPlayerItem src={isUrl} />
                </div>
              </div>
              <div className='item-liner-bottom' />
              <div className='item-liner-bottom-spacer' />
              <div className='workitem-content-container'>
                <div className='work-item-preview-ico'>
                  <PreviewList number={IndexConvert} />
                  <PreviewListVert number={IndexConvert} />
                  <PreviewListSquare number={IndexConvert} />
                </div>
              </div>
              <div className='workitem-content-container'>
                <span className='client-text'>director&nbsp;/&nbsp;</span>
                <span className='item-subtitle-text'>{work.director}</span>
              </div>
              <div className='workitem-content-container'>
                <span className='client-text'>client&nbsp;/&nbsp;</span>
                <span className='item-subtitle-text'>{work.client}</span>
              </div>
              <div className='workitem-content-container'>
                <IcoList number={IndexConvert} />
              </div>
              <div className='item-liner-bottom2' />
              <div className='item-liner-bottom-spacer' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
